<template>
  <div class="body">
    <div class="filter">
      <p>筛选：</p>
      <ul>
        <li :class="{current: type === 'all'}">
          <span @click="changeType('all')">全部新闻</span>
        </li>
        <li :class="{current: type === 'news'}">
          <span @click="changeType('news')">橙仕资讯</span>
        </li>
        <li :class="{current: type === 'reports'}">
          <span @click="changeType('reports')">媒体报道</span>
        </li>
      </ul>
    </div>

    <section class="box">
      <ul class="newsList">
        <li class="item" v-for="(item, index) in data.list" :key="index">
          <a v-if="item.categories[0] === 15" :href="item.metadata[0]" target="_blank">
            <p class="date">
              <em><strong>{{dateFormat(item.date, 'MM')}}</strong>/{{dateFormat(item.date, 'DD')}}</em>
              <span>{{dateFormat(item.date, 'YYYY')}}</span>
            </p>
            <p class="source">{{item.metadata[1]}}</p>
            <div class="thumbnail" :style="`background-image: url(${item.thumbnail})`" />
            <div class="summary">
              <h3>{{item.title.rendered}}</h3>
              <p class="desc">{{item.excerpt.rendered | removeHtml}}</p>
            </div>
          </a>
          <router-link v-else target="_blank" :to="`news/${item.id}`">
            <p class="date">
              <em><strong>{{dateFormat(item.date, 'MM')}}</strong>/{{dateFormat(item.date, 'DD')}}</em>
              <span>{{dateFormat(item.date, 'YYYY')}}</span>
            </p>
            <div class="thumbnail" :style="`background-image: url(${item.thumbnail})`" />
            <div class="summary">
              <h3>{{item.title.rendered}}</h3>
              <p class="desc">{{item.excerpt.rendered | removeHtml}}</p>
            </div>
          </router-link>
        </li>
        <li class="itemFill"></li>
        <li class="itemFill"></li>
      </ul>
      <div class="ft" v-if="data.totalPage > data.page">
        <span class="btnMore" @click="getList(type, data.page + 1)">{{data.loading ? '加载中…' : '查看更多'}}</span>
      </div>
    </section>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "News",
  data() {
    return {
      type: 'all',
      data: {
        list: [],
        loading: true,
        totalPage: 1,
        page: 0,
      }
    }
  },
  mounted() {
    this.getList('all', 1);
  },
  methods: {
    dateFormat(dateString = '', format="YYYY-MM-DD") {
      if (!dateString) return '';

      return dayjs(dateString).format(format);
    },
    getList(tab = 'reports', page = 1) {
      const perPage = 12;
      const cateMap = {
        all: '9,15',
        news: 9,
        reports: 15,
      };
      const category = cateMap[tab] || cateMap.all;
      
      this.data.loading = true;

      this.axios({
        url: `posts?categories=${category}&per_page=${perPage}&page=${page}`
      }).then((res) => {
        const { data, pagination } = res;
        const { pages } = pagination;
        const obj = {
          loading: false,
          totalPage: parseInt(pages) || 0,
          page
        }

        if (page === 1) {
          obj.list = data;
        } else {
          obj.list = [
            ...this.data.list,
            ...data,
          ]
        }

        this.data = obj;
      });
    },
    changeType(type) {
      this.type = type;

      this.getList(type, 1);
    },
  }
};
</script>

<style scoped lang="scss">
.body {
  margin: 0 auto;
  padding: 130px 0 0;
  max-width: 1380px;
}

.filter {
  display: flex;
  margin: 0 30px;
  padding-left: 20px;
  height: 120px;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 20px;
  line-height: 1;
  border-left: 6px solid #e83e0b;

  ul {
    display: flex;
    font-size: 30px;

    li {
      &.current {
        font-weight: bold;
      }

      span {
        display: inline-block;
        vertical-align: top;
        padding: 0 8px;
        cursor: pointer;
      }

      & + li {
        &::before {
          display: inline-block;
          vertical-align: top;
          margin: 1px 24px 0;
          width: 1px;
          height: 28px;
          overflow: hidden;
          content: '';
          background-color: #000;
        }
      }
    }
  }
}

.box {
  position: relative;
  padding: 0 0 124px;

  .ft {
    margin: 100px 0 0;
    text-align: center;

    .btnMore {
      position: relative;
      display: inline-block;
      vertical-align: top;
      padding: 0 46px;
      font-size: 22px;
      line-height: 60px;
      border-radius: 3px;
      cursor: pointer;

      &:hover {
        background-color: #efefef;
      }

      &::after {
        position: absolute;
        top: -50%;
        left: -50%;
        content: '';
        width: 200%;
        height: 200%;
        border: 3px solid #000;
        border-radius: 6px;
        transform: scale(.5);
      }
    }
  }
}

.newsList {
  display: flex;
  padding: 24px 0 0;
  flex-wrap: wrap;
  color: #333131;
  font-size: 16px;
  line-height: 22px;
  min-height: 200px;

  .item, .itemFill {
    flex: 1;
    padding: 0 30px;
    min-width: 30%;
    max-width: 460px;
  }

  .item {
    margin-top: 80px;
    position: relative;

    a {
      color: #333131;
    }

    .date {
      margin-bottom: 24px;
      padding-left: 10px;
      font-size: 20px;
      line-height: 1;
      font-style: italic;
      font-weight: 300;
      border-left: 2px solid #e83e0b;

      em {
        display: block;
        font-size: 40px;
        font-style: italic;
        font-weight: 300;
      }
      span {
        display: block;
        padding: 4px 20px;
      }
    }

    .source {
      position: absolute;
      top: 0;
      right: 30px;
      display: flex;
      align-items: flex-end;
      height: 68px;
      font-size: 16px;
      line-height: 20px;
      text-align: right;
    }

    .thumbnail {
      position: relative;
      margin-bottom: 30px;
      width: 100%;
      height: 0;
      padding-top: 56.25%;
      background: no-repeat 50%;
      background-size: cover;
      overflow: hidden;
    }

    h3, .desc {
      height: 110px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }

    h3 {
      margin-bottom: 8px;
      height: 60px;
      font-size: 20px;
      line-height: 30px;
      color: #333131;
      font-weight: bold;
      -webkit-line-clamp: 2;
    }
  }
}


@media only screen and (max-width: 500px) {
  .body {
    margin: 0 auto;
    padding: 24px;
  }

  .filter {
    margin: 0;
    height: 48px;
    font-size: 12px;
    border-left-width: 3px;

    ul {
      font-size: 14px;

      li {
        span {
          padding: 0 4px;
        }

        & + li {
          &::before {
          margin: 1px 6px 0;
          height: 12px;
        }
        }
      }
    }
  }

  .box {
    padding: 0 0 36px;

    .ft {
      margin: 32px 0 0;

      .btnMore {
        padding: 0 30px;
        font-size: 14px;
        line-height: 38px;

        &::after {
          border-width: 2px;
        }
      }
    }
  }

  .newsList {
    display: block;
    padding: 12px 0 0;
    min-height: 20px;
    font-size: 12px;
    line-height: 18px;

    .item {
      position: relative;
      margin-top: 36px;
      padding: 0;
      width: 100%;

      a {
        color: #333131;
      }

      .date {
        margin-bottom: 20px;
        padding-left: 8px;
        font-size: 16px;

        em {
          font-size: 32px;
        }
        span {
          padding: 2px 8px 0;
        }
      }

      .source {
        right: 0;
        height: 50px;
        font-size: 14px;
        line-height: 20px;
      }

      .thumbnail {
        margin-bottom: 20px;
      }

      h3 {
        height: auto;
        max-height: 48px;
        font-size: 16px;
        line-height: 24px;
      }

      .desc {
        height: auto;
        max-height: 90px;
      }
    }
  }
}
</style>
